<template>
  <v-container id="notas-cata">
    <v-row>
      <v-col>
        <nota-cata
          v-for="cata in content"
          :key="cata.name"
          :value="cata"
          class="darken-4"
          contain
          :id="'nota-cata-' + cata.name"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'NotasCata',
    components: {
      NotaCata: () => import('@/components/base/NotaCata'),
    },
    computed: {
      content () {
        return this.$store.state.notascata[this.$store.state.lang]
      },
    },
  }
</script>

<style scoped>

</style>
